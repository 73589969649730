import { Text, Image, Flex, Box, Tag, Tooltip, Button, ScaleFade, Icon, VStack, useDisclosure, Modal, ModalContent, ModalBody, ModalOverlay, ModalFooter, InputGroup, InputLeftAddon, InputRightAddon, Input, HStack } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useMutation } from "@tanstack/react-query";
import { MdFavorite } from "react-icons/md";
import { setFavourite } from "../../Helpers/Queries";
import DesktopInfo from "./DesktopInfo";
import DesktopPrice from "./DesktopPrice";
import ProductInfo from "./ProductInfo";
import { CustomToast } from "../../Helpers/AddToast";
import CartContext from "../../Hooks/CartContext";
import { useContext } from "react";
import { FaFire } from "react-icons/fa";

const TileLayout = (props) => {
  const { cart, setcart } = useContext(CartContext);
  const { addToast } = CustomToast();
  const { product, refresh } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mutation = useMutation(setFavourite, {
    onSuccess: (res) => {
      if (res.data.error) {
        addToast({ title: "Oh No!", message: res.data.message ? res.data.message : "An error occured, please try again.", type: "error" });
      } else {
        if (res.data.status) {
          product.favouriteUID = "1";
        } else {
          product.favouriteUID = null;
        }
        if (refresh) {
          refresh();
        }
      }
    },
    onError: (err) => {
      addToast({ title: "Oh No!", message: err.response.data.message ? err.response.data.message : "An error occured, please try again.", type: "error" });
    },
  });

  let orderqty = 0;
  if (cart) {
    if (cart.length > 0) {
      let cartcopy = JSON.parse(JSON.stringify(cart));
      cartcopy.map((cart) => {
        if (product.UID === cart.UID) {
          orderqty = cart.orderqty;
        }
        return true;
      });
    }
  }

  function addProductToCart(product, action = "add", qty = 1, selectedOption = []) {
    let newproduct = JSON.parse(JSON.stringify(product));
    if (!newproduct.selectedOptions) {
      newproduct.selectedOptions = [];
    }
    if (selectedOption.length > 0) {
      newproduct.selectedOptions = selectedOption;
    }

    if (newproduct.weighted === "1") {
      newproduct.sellinc = newproduct.sellinc * newproduct.cartonqty;
    }
    let newcart = [];
    let updated = false;
    if (cart.length > 0) {
      let cartcopy = JSON.parse(JSON.stringify(cart));
      newcart = cartcopy.map((prod) => {
        if (prod.UID === newproduct.UID) {
          prod.selectedOptions = selectedOption;
          if (action === "add") {
            if (prod.trackstock === "1") {
              if (parseInt(prod.quantity) < parseInt(prod.orderqty) + 1) {
                addToast({ title: prod.name, message: "Maxiumum order quantity is " + prod.quantity, type: "error" });
                prod.orderqty = prod.quantity;
              } else {
                prod.orderqty = parseInt(prod.orderqty) + 1;
              }
            } else {
              prod.orderqty = parseInt(prod.orderqty) + 1;
            }
          }
          if (action === "sub") {
            if (prod.orderqty !== 0) {
              prod.orderqty = parseInt(prod.orderqty) - 1;
            } else {
              if (prod.orderqty === 0) {
                prod.orderqty = 0;
              }
            }
          }
          if (action === "manual") {
            if (qty <= 0) {
              prod.orderqty = 0;
            } else {
              if (prod.trackstock === "1") {
                if (parseInt(prod.quantity) < parseInt(qty)) {
                  addToast({ title: prod.name, message: "Maxiumum order quantity is " + prod.quantity, type: "error" });
                  prod.orderqty = prod.quantity;
                } else {
                  prod.orderqty = qty;
                }
              } else {
                prod.orderqty = qty;
              }
            }
          }
          updated = true;
        }
        return prod;
      });
      if (!updated) {
        newproduct.orderqty = qty;
        newcart.push(newproduct);
      }
    } else {
      newproduct.orderqty = qty;
      newcart.push(newproduct);
    }
    newcart = newcart.filter(function (item) {
      return item.orderqty > 0;
    });
    setcart(newcart);
    localStorage.setItem("cart", JSON.stringify(newcart));
  }

  function isFavourite(UID) {
    if (UID) {
      return (
        <Tooltip hasArrow label="Remove from Saved Products" closeDelay={500}>
          <Button onClick={() => mutation.mutate(product.UID)} leftIcon={<Icon boxSize={4} as={MdFavorite} />} size={"xs"} variant="outline" colorScheme="red">
            SAVED
          </Button>
        </Tooltip>
      );
    }
    return (
      <Tooltip hasArrow label="Add to Saved Products" closeDelay={500}>
        <Button onClick={() => mutation.mutate(product.UID)} leftIcon={<Icon boxSize={4} as={MdFavorite} />} size={"xs"} variant="solid" colorScheme="red">
          SAVE
        </Button>
      </Tooltip>
    );
  }

  let addToCart = (
    <>
      <Button bgColor={"brand.400"} color={"white"} _hover={{ bgColor: "brand.900" }} style={{ width: "100%" }} onClick={() => addProductToCart(product)}>
        Add to cart
      </Button>
    </>
  );

  if (orderqty !== 0) {
    addToCart = (
      <>
        <InputGroup className="tile-add-to-cart" style={{ width: "100%" }}>
          <InputLeftAddon padding={0}>
            <Button bgColor={"brand.400"} color="white" _hover={{ bgColor: "brand.900" }} onClick={() => addProductToCart(product, "sub")}>
              <MinusIcon />
            </Button>
          </InputLeftAddon>
          <Input
            textAlign={"center"}
            type="number"
            style={{ backgroundColor: "#F8F8F8" }}
            defaultValue={parseInt(orderqty)}
            key={Math.random()}
            onFocus={(e) => e.target.select()}
            onBlur={(e) => addProductToCart(product, "manual", e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addProductToCart(product, "manual", e.target.value);
              }
            }}
          />
          <InputRightAddon padding={0}>
            <Button bgColor={"brand.400"} isDisabled={product.trackstock === "1" && parseInt(product.quantity) <= parseInt(orderqty)} color="white" _hover={{ bgColor: "brand.900" }} onClick={() => addProductToCart(product)}>
              <AddIcon />
            </Button>
          </InputRightAddon>
        </InputGroup>
      </>
    );
  }

  let border = "";
  let showBubble = "";
  if (product.tag_name && product.sellinc === product.normalsellinc) {
    border = product.tag_background_color + " solid 5px";
  }
  if (product.sellinc !== product.normalsellinc) {
    //calculate the discount
    let discount = (product.normalsellinc - product.sellinc).toFixed(2);
    //if the product has gst, subtract 10% from the discount
    if (product.gst === "1") {
      discount = (discount / 1.1).toFixed(2);
    }
    //if the number is a whole number, remove the decimal places
    if (discount % 1 === 0) {
      discount = parseFloat(discount).toFixed(0);
    }
    border = "#F00 solid 5px";
    showBubble = (
      <div className="bubbleOuter">
        <div className="bubble">
          <div className="bubbleInner">
            SAVE
            <br />${discount}
          </div>
        </div>
      </div>
    );
  }

  return (
    <ScaleFade initialScale={0.9} in>
      <Box bg="white" w="full" rounded={10} justifyContent="center" overflow={product.tag_name && "hidden"} m={0} border={border}>
        <Flex shadow="xl" direction="column">
          <Box position="relative">
            <Image w="100%" maxW={"300px"} h="auto" mt={8} mx="auto" fallbackSrc="https://manager.myorder.direct/assets/images/tpm_loading_small.gif" src={"https://manager.myorder.direct/uploads/thumbnails/r_" + product.thumbnail} />
            {showBubble}
            {product.tag_name && product.sellinc === product.normalsellinc && (
              <Tag
                position="absolute"
                rounded={0}
                fontSize={"md"}
                pb={1}
                top={0}
                left={0}
                width={"100%"}
                zIndex={10}
                justifyContent="center"
                textTransform={"uppercase"}
                size={"sm"}
                variant="solid"
                bg={product.tag_background_color}
                color={product.tag_text_color}
              >
                {product.tag_name}
              </Tag>
            )}
            {product.sellinc !== product.normalsellinc && (
              <Tag position="absolute" fontWeight={"bold"} rounded={0} fontSize={"md"} pb={1} top={0} left={0} width={"100%"} zIndex={10} justifyContent="center" textTransform={"uppercase"} size={"sm"} variant="solid" bg={"#F00"} color={"#FFFFFF"}>
                <HStack>
                  <Box>
                    <FaFire color="yellow" />
                  </Box>
                  <Text pt={"0.5"}>RED HOT SPECIAL</Text>
                  <Box>
                    <FaFire color="yellow" />
                  </Box>
                </HStack>
              </Tag>
            )}
          </Box>
          <Flex>
            <Box p={4} flexGrow={1}>
              <VStack alignItems="normal" spacing={2}>
                <Box>{isFavourite(product.favouriteUID)}</Box>
                <Box minH={"70px"}>
                  <Text as="b" size={"xl"} textTransform={"uppercase"}>
                    {product.name}
                  </Text>
                  <Text fontSize={{ base: "14px", lg: "16px" }} color={"teal.900"} fontWeight={"400"} textTransform={"uppercase"} dangerouslySetInnerHTML={{ __html: product.extendedinfo }} />
                </Box>
                <DesktopInfo product={product} onOpen={onOpen} />
                <DesktopPrice addToCart={addToCart} product={product} />
              </VStack>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Modal trapFocus={false} scrollBehavior="inside" size={"xl"} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(20px)" />
        <ModalContent>
          <ModalBody>
            <ProductInfo addToCart={addToCart} product={product} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={"red"} size={"sm"} onClick={onClose}>
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ScaleFade>
  );
};

export default TileLayout;
