import React, { useState, useEffect } from 'react';
import API from "./API"

const WholesalerAddress = (props) => {
    const [_wholesaler] = useState(props.wholesaler);
    const [_storeAddress, setStoreAddress] = useState([]);
    const [_loadingAddress, setLoadingAddress] = useState(true);
    const [_errorAddress, setErrorAddress] = useState(false);
    const [_showName] = useState(true);
    const [_showABN] = useState(true);

    useEffect(() => {
        API.get("/get_wholesaler_address.php?uid="+_wholesaler, {headers: {"Content-Type": "application/json", "SECTOKEN": localStorage.getItem("sectoken")}})
            .then(res => {
                if(!res.data.error){
                    setStoreAddress(res.data.data)
                    setLoadingAddress(false)
                    setErrorAddress(false)
                } else {
                    setErrorAddress(true)
                    setLoadingAddress(false)
                }
            })
            .catch(res=>{
                setErrorAddress(true)
                setLoadingAddress(false)
            })
    }, [_wholesaler]);

    let storeAddress = "Loading Details..."
    if(!_loadingAddress){
        if(!_errorAddress){
            let storeDetails = _storeAddress
            storeAddress = <span className="text-uppercase" style={{fontSize:'13px'}}>
            {(_showName) && <><strong>{storeDetails.name}</strong><br /></> }
            {(_showABN) && <><small>{(storeDetails.abn) && <>ABN: {storeDetails.abn.substring(0, 2)+" "+storeDetails.abn.substring(2, 5)+" "+storeDetails.abn.substring(5, 8)+" "+storeDetails.abn.substring(8, 11)}</> }</small><br /></> }
            {(storeDetails.street_unit) && storeDetails.street_unit+"/"}
            {storeDetails.street_number} {storeDetails.street_name}, {storeDetails.suburb}<br />
            {storeDetails.state}, {storeDetails.country}, {storeDetails.postcode}<br />
            <div className="mt-1">{(storeDetails.phone.substring(0, 2) !== "04") ? "("+storeDetails.phone.substring(0, 2)+") "+storeDetails.phone.substring(2, 6)+" "+storeDetails.phone.substring(6, 11) : storeDetails.phone}</div>
            </span>
        } else {
            storeAddress = "An error occured loading the wholesaler details, please try again."
        }
    }

    return (
        <div>
            {storeAddress}
        </div>
    );
}

export default WholesalerAddress;