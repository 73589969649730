import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Button,
  VStack,
  Text,
  Icon,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { GoLocation } from 'react-icons/go';
import { BsPhone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import Banner from '../Callouts/Banner';
import { FaFax } from 'react-icons/fa';
import { MdBusiness } from 'react-icons/md';

const contactOptions = [
  {
    label: 'Address',
    value: '23 Jarrah Drive,  Braeside, VIC, 3195',
    icon: GoLocation,
    colSpan: {base: 1, md: 2}
  },
  {
    label: 'PHONE NUMBER',
    value: '(03) 9543 3100',
    icon: BsPhone,
    colSpan: {base: 1, md: 2}
  },
  {
    label: 'FAX NUMBER',
    value: '(03) 9543 3900',
    icon: FaFax,
    colSpan: {base: 1, md: 2}
  },
  {
    label: 'EMAIL',
    value: <a href="mailto:customerservice@tpmpackaging.com.au">customerservice@tpmpackaging.com.au</a>,
    icon: HiOutlineMail,
    colSpan: {base: 1, md: 3}
  },
  {
    label: 'ABN',
    value: '77 130 108 947',
    icon: MdBusiness,
    colSpan: {base: 1, md: 3}
  }
];

const Contact = () => {
  return (
      <>
        <VStack
            spacing={2}
            py={5}
        >
        <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
            <Banner
                pageName="Contact Us"
                subText="Our Customer Service team is here to help! Get in touch with us today and our team will be able to assist with your enquiry."
                backgroundImage="https://images.unsplash.com/photo-1592890288564-76628a30a657?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" />
        </Container>
        <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
            <Grid templateColumns={{base: 'repeat(1, 1fr)', md:'repeat(6, 1fr)'}} gap={'40px'}>
            {contactOptions.map((option, index) => (
            <GridItem colSpan={option.colSpan} w='100%' key={index}>
                    <Stack spacing={3} direction="column" justify="center" alignItems="center" px={3}>
                    <Icon as={option.icon} w={10} h={10} color={'brand.400'} />
                    <Text fontSize="lg" fontWeight="semibold">
                    {option.label}
                    </Text>
                    <Text fontSize="md" textAlign="center">
                    {option.value}
                    </Text>
                </Stack>
            </GridItem>
          ))}
            </Grid>
        </Container>
        <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
        <form>
          <VStack spacing={4} w="100%">
            <Stack w="100%" spacing={3} direction={{ base: 'column', md: 'row' }}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input name="name" type="text" rounded="md" />
              </FormControl>
              <FormControl>
                <FormLabel>Contact Number</FormLabel>
                <Input name="phone" type="text" rounded="md" />
              </FormControl>
            </Stack>
            <FormControl>
                <FormLabel>Email Address</FormLabel>
                <Input name="email" type="email" rounded="md" />
              </FormControl>
            <FormControl>
              <FormLabel>Message</FormLabel>
              <Textarea name="enquiry" size="lg" rounded="md" />
            </FormControl>
          </VStack>
          <VStack w="100%" mt={5}>
            <Button
              bg="green.300"
              color="white"
              _hover={{
                bg: 'green.500'
              }}
              rounded="md"
              w={{ base: '100%', md: 'max-content' }}
            >
              Send Message
            </Button>
          </VStack>
        </form>
        </Container>
      </VStack></>
  );
};

export default Contact;