import API from "./API"

export function getCategoriesList(){
    return API.get("/get_categories_list.php", {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductList(){
    return API.get("/get_featured_products.php", {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListByCategory(category, page){
    return API.post("/get_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000", "category": category}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListBySubCategory(subcategory, page){
    return API.post("/get_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000", "subcategory": subcategory}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListBySubSubCategory(subsubcategory, page){
    return API.post("/get_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000", "subsubcategory": subsubcategory}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function setFavourite(uid){
    return API.post("/post_add_favourite.php",{"productuid":uid}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListBySaved(page){
    return API.post("/get_saved_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000"}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListBySearch(page, searchterm){
    return API.post("/get_search_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000", "search": searchterm}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getProductListByReorder(page){
    return API.post("/get_reorder_products.php",{"page":page, "count":50, "sortby":"nameasc", "filterby":"00000000-0000-0000-0000-000000000000"}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function validateToken(token){
    return API.post("/validate_token.php", {sectoken: token}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function login({email, password}){
    if(!email){
        return false
    }
    if(!password){
        return false
    }
    return API.post("/login.php", {email: email, password: password});
}

export function createUser({firstname, lastname, email, phone, company, password, password2, address, abn}){
    return API.post("/register.php", {firstname: firstname, lastname: lastname, email: email, phone: phone, company: company, password: password, address: address, abn: abn});
}

export function sendResetLink({email}){
    if(!email){
        return false
    }
    return API.post("/send_password.php", {email: email});
}

export function checkCart({ queryKey }){
    // eslint-disable-next-line no-unused-vars
    const [_key, { cart }] = queryKey
    if(cart){
        if(cart.length > 0){
            return API.post("/post_check_cart.php", {cart: cart}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
        }
    }
    return false
}

export function createOrder({cartResults, noteResults, addressResults, customerResults, poResults}){
    return API.post("/post_send_order.php", {cart: cartResults, notes: noteResults, address: addressResults, customer: customerResults, po: poResults}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function userDetails(){
    return API.get("/get_customer_details.php", {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getOrder(orderUID){
    return API.get("/get_order.php?orderuid="+orderUID, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function getOrderB(orderUID){
    return API.get("/get_order_b.php?orderuid="+orderUID, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function setPersonal(details){
    return API.post("/post_update_personal.php", {details}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function setAddress(details){
    return API.post("/post_update_address.php", {details}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}

export function setPassword(details){
    return API.post("/post_update_password.php", {details}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}});
}