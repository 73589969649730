import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Icon,
  Grid,
  Image,
  VStack,
  Heading
} from '@chakra-ui/react';
import { MdLogin } from 'react-icons/md';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const LoginLGup = ({mutation, email, password, setPassword, setEmail}) => {
  const navigate = useNavigate()
  return (
    <Grid
      templateColumns={{
        base: "repeat(2, 1fr)",
        xl: "repeat(3, 1fr)"
      }}
      gap={6}
    >
      <VStack alignItems={'flex-start'}>
      <Box maxH={'150px'}>
        <Image src="/logos/tpm_logo.png" alt="TPM" maxH={'150px'} />
      </Box>
      <Box pt={'100px'}>
        <Heading as="h1" size="2xl" mb={8} ml={'40px'} color={'theme.blueText'}>WELCOME TO <br />LOOP.</Heading>
        <Heading as="h1" size="xl" ml={'40px'} color={'lightgrey'}>WHERE ALL YOUR<br/>PACKAGING, CLEANING<br />AND LABELLING<br />REQUIREMENTS ARE</Heading>
        <Heading as="h1" size="xl" ml={'40px'} color={'theme.blueText'}>SOLVED.</Heading>
      </Box>
      </VStack>
      <Box
    position="relative"
      border="20px solid"
      borderColor="#b2d9f3"
      borderRadius="60px"
      padding="0px"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: "-20px",
        borderRadius: "60px",
        border: "20px solid #b2d9f3",
      }}
      >
    <Stack spacing={8} mx={'auto'} maxW={'lg'} pb={10} pt={8} px={4}>
        <Stack align={'center'}>
          <Image src="/logos/loop_logo.png" maxHeight={'200'} alt="LOOP" />
          <Box height={'6px'} w={'100%'} backgroundColor={'#E8E8E8'} />
        </Stack>
          <Stack spacing={4}>
            <form onSubmit={(e)=>{e.preventDefault(); mutation.mutate({email, password})}}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input isDisabled={mutation.isLoading} value={email} onChange={(e)=>setEmail(e.target.value)} type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input isDisabled={mutation.isLoading} value={password} onChange={(e)=>setPassword(e.target.value)} type="password" />
            </FormControl>
            <Stack spacing={4}>
              <Stack
                mb={6}
                mt={3}
                direction={'column'}
                align={'end'}>
                <Link color={'brand.400'} href={"/forgot-password"} zIndex={'1'}>Forgot password?</Link>
              </Stack>
              <Button fontWeight={'normal'} fontSize={'lg'} leftIcon={<Icon as={MdLogin} />} type={'submit'} colorScheme={'whatsapp'} isDisabled={(!email || !password)} isLoading={mutation.isLoading}>
                Sign in
              </Button>
              <Button fontWeight={'normal'} fontSize={'lg'} onClick={()=>navigate("/create-account")} leftIcon={<Icon as={HiOutlineClipboardList} />} type={'button'} colorScheme={'blue'}>
                Create Account
              </Button>
            </Stack>
            </form>
          </Stack>
      </Stack>
      </Box>
      <Box />
    </Grid>
    );
  }

export default LoginLGup