import { Flex } from "@chakra-ui/react";
import { useOutlet } from "react-router-dom";
import TopHeader from "../Components/Header/TopHeader";

const Layout = props => {
    const outlet = useOutlet();
    return(
        <>
            <TopHeader isAuth={false} />
            <Flex
                minH={'calc(100% - 106px)'}
                align={'center'}
                justify={'center'}>
                {outlet}
            </Flex>
        </>
    )
}

export default Layout