import {
    Box,
    Container,
    Flex,
    Heading,
    HStack,
    Link,
    Progress,
    Stack
  } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkCart } from '../../Helpers/Queries'
import CartContext from '../../Hooks/CartContext'
import NotFoundCart from '../NotFound/NotFoundCart'
import { CartOrderSummary } from './CartOrderSummary'
import StoreTile from './StoreTile'
const PreviewCart = () => {
    const {cart} = useContext(CartContext)
    const navigate = useNavigate()
    const { isLoading, isError, data } = useQuery(['checkCart', {cart: cart}], checkCart)

    if(isLoading){
        return (
            <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
                <Progress size='lg' colorScheme={'blue'} isIndeterminate />
            </Container>
        )
    }

    if(isError || !data){
        return <NotFoundCart />
    }

    if(data.data.error){
      return <NotFoundCart />
    }

    let orderList = data.data.order.map((val) => { 
        let ordererror = false
        let orderqty = val.products.reduce( function(cnt,o){ return cnt + parseInt(o.orderqty); }, 0)
        if(orderqty < val.zone_min_items){
            ordererror = true
        }
        return <StoreTile key={val.companyUID} storeInfo={val} orderqty={ordererror} zone_min_items={val.zone_min_items} />
    })


    return(
      <Box
        maxW={{ base: '3xl', lg: '7xl' }}
        mx="auto"
        px={{ base: '4', md: '8', lg: '2' }}
        py={{ base: '6', md: '8', lg: '12' }}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ lg: 'flex-start' }}
          spacing={{ base: '8', md: '8' }}
        >
          <Stack spacing={{ base: '8', md: '10' }} flex="2">
            <Heading fontSize="2xl" fontWeight="extrabold">
              Shopping Cart
            </Heading>
    
            <Stack spacing="6">
              {orderList}
            </Stack>
          </Stack>
          
          <Flex direction="column" align="center" flex="1" >
            <Box mt={68} w={'full'} bgColor={'white'}>
            <CartOrderSummary style={{marginTop:"50px !important"}} order={data.data.order[0]} />
            <HStack textAlign={'center'} my="6" fontWeight="semibold">
              <Box my={'auto'} width={'full'}>
              or
              <Link ml={2} onClick={()=>navigate("/")} color={'brand.400'}>Continue shopping</Link>
              </Box>
              
            </HStack>
            </Box>
            
          </Flex>
        </Stack>
      </Box>
      )
  }

  export default PreviewCart