import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom';
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from './Hooks/AuthContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 5*60*1000,
    },
  },
})

const theme = extendTheme({
  fonts: {
    heading: `'Varela Round', sans-serif`,
    body: `'Varela Round', sans-serif`,
  },
  colors: {
    brand: {
      100: "#0099ff",
      400: '#003399',
      900: "#002266",
    },
    theme: {
      blueText: '#2aabe2',
      greyText: 'lightgrey',
    }
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </>
);
reportWebVitals();
