import {
    HStack,
    Stack,
    Tag,
    TagLeftIcon,
    TagLabel,
    Tooltip,
    Button,
    Flex
  } from '@chakra-ui/react';
import { FaBox, FaLayerGroup, FaPallet } from 'react-icons/fa';

const DesktopInfo = (props) => {
    const { product, onOpen } = props
    return (
        <><Flex><Stack flex={1} justify={'flex-end'} direction={'row'} spacing={6}>
                    <Tag textTransform={'uppercase'} size={'sm'} variant='subtle'>
                        #{product.ordercode}
                    </Tag>
                </Stack></Flex>
                    
                    <Flex mt={1}>
                    <Stack flex={1} justify={'flex-start'} direction={'row'} spacing={6}>
                    <HStack>
                        <Button onClick={onOpen} textTransform={'uppercase'} size={'xs'}  colorScheme={'teal'} variant='solid'>
                            More Info
                        </Button>
                    </HStack>
                </Stack>
                <Stack flex={1} justify={'flex-end'} direction={'row'} spacing={6}>
                    <HStack>
                        {(product.cartonqty !== "0") &&
                        <Tooltip placement='top' hasArrow label='Carton Quantity' closeDelay={500}>
                            <Tag size={'sm'} variant='outline' colorScheme='green'>
                                <TagLeftIcon as={FaBox} />
                                <TagLabel>{product.cartonqty}</TagLabel>
                            </Tag>
                        </Tooltip>
                        }
                        {(product.layerqty !== "0") &&
                        <Tooltip placement='top' hasArrow label='Layer Quantity' closeDelay={500}>
                            <Tag size={'sm'} variant='outline' colorScheme='green'>
                                <TagLeftIcon as={FaLayerGroup} />
                                <TagLabel>{product.layerqty}</TagLabel>
                            </Tag>
                        </Tooltip>
                        }
                        {(product.palletqty !== "0") &&
                        <Tooltip placement='top' hasArrow label='Pallet Quantity' closeDelay={500}>
                            <Tag size={'sm'} variant='outline' colorScheme='green'>
                                <TagLeftIcon as={FaPallet} />
                                <TagLabel>{product.palletqty}</TagLabel>
                            </Tag>
                        </Tooltip>
                        }
                    </HStack>
                </Stack>
                    </Flex>
</>)
}

export default DesktopInfo