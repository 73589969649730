import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

export default function OrderSuccess() {
  const navigate = useNavigate()
  const params = useParams()
  let orderid = params.orderid
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        as="h1"
        size="4xl"
        color={'brand.400'}>
        Thank You!
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Your order has been placed successfully!
      </Text>
      <Text color={'gray.500'} mb={6}>
        for reference, your order number is #{orderid}
      </Text>

      <Button
        onClick={()=>navigate("/")}
        colorScheme={'blue'}
        >
        Start Shopping Again!
      </Button>
    </Box>
  );
}