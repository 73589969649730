import { SimpleGrid } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CustomToast } from '../../Helpers/AddToast';
import TileLayout from '../ProductTile/TileLayout';

const StoreTile = props => {
    const { addToast } = CustomToast();
    const [orderproducts, setorderproducts] = useState(props.storeInfo.products)
    const [unavailable, setunavailable] = useState(props.storeInfo.unavailable)
    useEffect(()=>{
        setorderproducts(props.storeInfo.products)
    },[props.storeInfo.products, props.orderqty])

    useEffect(()=>{
        setunavailable(props.storeInfo.unavailable)
    },[props.storeInfo.unavailable])

    let products

    if(orderproducts.length > 0){
        let ordercopy = orderproducts
        products = ordercopy.map((val)=>{
            return <TileLayout key={val.UID} product={val} />
        })
    } else {
        addToast({title: "Oh No!", message: "There are no products to display. If you believe this is an error please contact us.", type: "error"})
    }
    if(unavailable === "1"){
        addToast({title: "Oh No!", message: "Some of your order items were unavailable, your cart has been updated.", type: "error"})
    }

    return (
        <SimpleGrid columns={[1, null, 1, 2]} spacing='10px'>
          {products}
        </SimpleGrid>
    );
}


export default StoreTile;