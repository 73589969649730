import { Container, Progress, SimpleGrid } from "@chakra-ui/react";
import TileLayout from "../ProductTile/TileLayout";
import { useQuery } from "@tanstack/react-query";
import { getProductList } from "../../Helpers/Queries";
import { CustomToast } from "../../Helpers/AddToast";
import Banner from "./Banner";

function FeaturedProducts() {
  const { addToast } = CustomToast();
  let products = [];
  const { isLoading, isError, data } = useQuery({
    queryKey: ["productList"],
    queryFn: getProductList,
  });

  if (isLoading) {
    return (
      <Container maxW="container.xl" bg="white" py={5} minH={"100%"} color="black">
        <Progress size="lg" colorScheme={"blue"} isIndeterminate />
      </Container>
    );
  }

  if (!isLoading && !isError) {
    products = data.data.products.map((val) => {
      return <TileLayout key={val.UID} product={val} />;
    });
  }

  if (isError) {
    addToast({ title: "Oh No!", message: "An error occured loading the product list.", type: "error" });
  }

  return (
    <>
      <Container maxW="container.xl" bg="white" py={5} minH={"100%"} color="black">
        <Banner
          pageName={"We've selected some of our most popular or new products below for you to browse."}
          backgroundImage="https://images.unsplash.com/photo-1587293852726-70cdb56c2866?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1744&q=80"
        />
      </Container>
      <Container maxW="container.xl" bg="white" h={"100%"} py={5} minH={"100%"} color="black">
        <SimpleGrid columns={[1, null, 2, 3, 4]} spacing="10px">
          {products}
        </SimpleGrid>
      </Container>
    </>
  );
}

export default FeaturedProducts;
