import {
    Flex,
    Text,
    Stack,
    Collapse,
    Icon,
    useColorModeValue,
    useDisclosure,
    Link
  } from '@chakra-ui/react';
  import {
    ChevronDownIcon,
  } from '@chakra-ui/icons';
import { MdContactPhone, MdRefresh } from 'react-icons/md';

const MobileNav = (props) => {
    const { NAV_ITEMS } = props
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
        <Stack spacing={4}>
          <Flex
            py={2}
            as={Link}
            href={'/reorder'}
            justify={'flex-start'}
            align={'center'}
            fontWeight={600}
            _hover={{
              textDecoration: 'none',
            }}>
            <Icon w={3} h={3} as={MdRefresh} />&nbsp;Reorder Products
          </Flex>
        </Stack>
        <Stack spacing={4}>
          <Flex
            py={2}
            as={Link}
            href={'/contact'}
            justify={'flex-start'}
            align={'center'}
            fontWeight={600}
            _hover={{
              textDecoration: 'none',
            }}>
            <Icon w={3} h={3} as={MdContactPhone} />&nbsp;Contact Us
          </Flex>
        </Stack>
      </Stack>
    );
};
  
const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleParentClick = (e) => {
    if (children) {
      onToggle();
    }
  };

  return (
    <Stack spacing={4} width={'100%'}>
      <Flex
        py={2}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          as={Link}
          onClick={handleParentClick}
          href={href ?? '#'}
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            onClick={onToggle}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={1}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <MobileNavItem key={child.label} {...child} />
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNav