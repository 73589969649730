import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function NotFoundProducts() {
    const navigate = useNavigate()
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        as="h1"
        size="4xl"
        color={'brand.400'}>
        Sorry
      </Heading>
      <Text fontSize="18px" mt={10} mb={2}>
        No results found
      </Text>
      <Text color={'gray.500'} mb={6}>
        If you're looking for something in particular please contact us and we will try to accomidate your needs.
      </Text>

      <Button
        onClick={()=>navigate("/contact")}
        colorScheme={'blue'}
        >
        Contact Us
      </Button>
    </Box>
  );
}