import { useState } from "react";
import { Box, Button, Input, InputGroup, InputRightElement, Text, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdSearch } from "react-icons/md";

const Search = (props) => {
    const navigate = useNavigate()
    const [value, setValue] = useState('');
    return (
    <form
      onSubmit={e=> {
        e.preventDefault();
        navigate("/search/"+value, { replace: true })
        setValue('')
      }}>
        <Box mb={2}>
            <Text fontWeight={'bold'} fontSize={'md'}>Can't find what you're looking for? Why not search our thousands of products....</Text>
        </Box>
        <InputGroup size='md'>
            <Input focusBorderColor={'blue'} value={value} onChange={(e)=> setValue(e.currentTarget.value)} />
            <InputRightElement width={'100px'}>
                <Button colorScheme={'blue'} color={'white'} isDisabled={(!value)} type="submit"><Icon fontSize={'lg'} mr={2} as={MdSearch} />Search</Button>
            </InputRightElement>
        </InputGroup>
    </form>
    )
}

export default Search