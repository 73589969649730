import { Box, Button, Icon } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { MdShoppingBasket } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import CartContext from "../../Hooks/CartContext";
import { formatPrice } from './PriceTag'

const FloatingButton = props => {
    const location = useLocation()
    const { showCart } = props
    const {cart} = useContext(CartContext)
    const navigate = useNavigate()
    const [cartItems, setcartItems] = useState(0)
    const [cartTotal, setcartTotal] = useState(0)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }
    const prevCart = usePrevious(cart)
    useEffect(() => {
        if (cart !== prevCart) {
            let cartTotal = 0
            let cartItems = 0
            if(cart.length > 0){
                cart.map((val)=>{
                    if(val.gst !== "1"){
                        cartTotal = parseFloat(cartTotal) + parseFloat(val.sellinc*val.orderqty)
                        cartItems = parseInt(cartItems) + parseInt(val.orderqty)
                        return true
                    }
                    cartTotal = parseFloat(cartTotal) + parseFloat((val.sellinc-(val.sellinc/11))*val.orderqty)
                    cartItems = parseInt(cartItems) + parseInt(val.orderqty)
                    return true
                })
            }
            setcartItems(parseInt(cartItems));
            setcartTotal(cartTotal)
        }
        // eslint-disable-next-line
    }, [cart]);

    if(location.pathname === "/cart"){
        return false
    }

    if(location.pathname === "/checkout"){
        return false
    }

    let cartItemDisplay
    let cartTotalDisplay

    if(cartItems > 0){
        cartItemDisplay = "("+cartItems+")"
    }

    if(cartTotal > 0){
        cartTotalDisplay = formatPrice(cartTotal)
    }

    return ( <Box
        display={showCart}
        position='fixed'
        boxShadow='md'
        bottom='50px'
        right={['16px', '16px', '16px', '16px', '16px', '150px']}
        zIndex={3}
        sx={{'@media print': {display: 'none',},}}
        >
        <Button
            onClick={()=>navigate("/cart")}
            size={'lg'}
            leftIcon={<Icon as={MdShoppingBasket} />}
            bgColor='brand.100'
            _hover={{backgroundColor: 'brand.400'}}
            border='solid 3px white'
            color={'white'}
            isDisabled={cartItems <= 0}
            variant='solid'>
            View Cart {cartItemDisplay} {cartTotalDisplay}
        </Button>
      </Box>)
}

export default FloatingButton