import {
    Button,
    Flex,
    Heading,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
  import { formatPrice } from './PriceTag'
  const OrderSummaryItem = (props) => {
    const { label, value, children } = props
    return (
      <Flex justify="space-between" fontSize="sm">
        <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
          {label}
        </Text>
        {value ? <Text fontWeight="medium">{value}</Text> : children}
      </Flex>
    )
  }
  
  export const CartOrderSummary = props => {
    const navigate = useNavigate()
    const { order, submit } = props

    let info = order
    let orderQty = info.products.reduce( function(cnt,o){ return cnt + parseInt(o.orderqty); }, 0)
    let total = 0
    let totalex = 0
    let gst = 0
    let delivery = 0
    let surcharge = 0
    let deliverytype = ""
    let surchargetype = ""
    let deliverycalc = 0.00
    let surchargecalc = 0.00
    if(info.products.length > 0){
        info.products.map((val)=>{

            let selltotal = parseFloat(val.sellinc)*parseInt(val.orderqty)
            let selltotalex = parseFloat(val.sellinc*val.orderqty)
            if(val.weighted === "1"){
                selltotal = parseFloat(val.sellinc*val.cartonqty)*parseInt(val.orderqty)
            }
            
            if(val.gst === "1"){
              selltotalex = parseFloat((val.sellinc-(val.sellinc/11))*val.orderqty);
              let sellgst = parseFloat(parseFloat(val.sellinc/11)*parseInt(val.orderqty))
              gst = parseFloat(parseFloat(gst)+parseFloat(sellgst))
            }
            total = parseFloat(parseFloat(total)+parseFloat(selltotal));
            totalex = parseFloat(parseFloat(totalex)+parseFloat(selltotalex));
            return true
        })
    }
    if(info.zone_freight_calc === "fixed" && info.zone_freight_amount !== "0.00"){
        delivery = parseFloat(info.zone_freight_amount)
        deliverytype = " (FIXED)"
        deliverycalc = info.zone_freight_amount
    }
    if(info.zone_surcharge_calc === "fixed" && info.zone_surcharge_amount !== "0.00"){
        surcharge = parseFloat(info.zone_surcharge_amount)
        surchargetype = " (FIXED)"
        surchargecalc = info.zone_surcharge_amount
    }
    if(info.zone_freight_calc === "percent" && info.zone_freight_amount !== "0.00"){
        delivery = parseFloat(total)*parseFloat(info.zone_freight_amount/100)
        deliverytype = " ("+info.zone_freight_amount+"%)"
        deliverycalc = parseFloat(total)*parseFloat(info.zone_freight_amount/100)
    }
    if(info.zone_surcharge_calc === "percent" && info.zone_surcharge_amount !== "0.00"){
        surcharge = parseFloat(total)*parseFloat(info.zone_surcharge_amount/100)
        surchargetype = " ("+info.zone_surcharge_amount+"%)"
        surchargecalc = parseFloat(total)*parseFloat(info.zone_surcharge_amount/100)
    }


    let button = <Button onClick={()=>navigate("/checkout")} colorScheme="blue" size="lg" fontSize="md" rightIcon={<FaArrowRight />}>
      Checkout
    </Button>

    if(submit){
      button = ""
    }


    
    return (
      <Stack spacing="8" padding="8" width="full">
        <Heading size="md">Order Summary</Heading>
  
        <Stack spacing="6">
            <OrderSummaryItem label="Total Items" value={orderQty} />
            <OrderSummaryItem label={"Delivery "+deliverytype} value={formatPrice(delivery)} />
            <OrderSummaryItem label={"Surcharge "+surchargetype} value={formatPrice(surcharge)} />
            <OrderSummaryItem label="Subtotal" value={formatPrice(totalex)} />
            <OrderSummaryItem label="GST" value={formatPrice(gst)} />
            <Flex justify="space-between">
                <Text fontSize="lg" fontWeight="semibold">
                Total (Inc. GST)
                </Text>
                <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(parseFloat(total)+parseFloat(deliverycalc)+parseFloat(surchargecalc))}
                </Text>
            </Flex>
        </Stack>
        {button}
      </Stack>
    )
  }