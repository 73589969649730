import { useToast } from "@chakra-ui/react";

export const CustomToast = () => {
    const toast = useToast();
    // types are: "success", "info", "warning", "error"

    const addToast = (newRes) => {
        toast({
            title: newRes.title,
            description:newRes.message, 
            status: newRes.type, 
            position:"top-right", 
            isClosable: newRes.isClosable !== undefined ? newRes.isClosable : true, 
            duration: newRes.duration !== undefined ? newRes.duration : 5000, 
        })
    }
    
    return { addToast };
}