import { Box, Heading, Text, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function NotFoundUser() {
    const navigate = useNavigate()
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        as="h1"
        size="4xl"
        color={'brand.400'}>
        Sorry
      </Heading>
      <Text fontSize="18px" mt={10} mb={2}>
        Unable to retrieve your account details. If you believe this is an error please reload the page and try again.
      </Text>
      <Text color={'gray.500'} mb={6}>
        <Link onClick={()=>navigate("/")} color={'brand.400'}>Continue shopping</Link>
      </Text>
    </Box>
  );
}