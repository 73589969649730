import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
    ChevronRightIcon,
  } from '@chakra-ui/icons';
import { MdContactPhone, MdRefresh } from 'react-icons/md';

const DesktopNav = (props) => {
    const { NAV_ITEMS } = props
    const linkHoverColor = useColorModeValue('gray.200', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.200');
  
    return (
      <Stack direction={'row'} wrap={'wrap'} justify={'center'} maxWidth={'7xl'}>
        {NAV_ITEMS.map((navItem) => (
          <Box p={1} key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={1}
                  href={navItem.href ?? '#'}
                  fontSize={'md'}
                  fontWeight={600}
                  color={'white'}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'none'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
        <Box p={1}>
          <Link
            href={'/reorder'}
            fontSize={'md'}
            fontWeight={600}
            color={'white'}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}>
            <Icon w={3} h={3} as={MdRefresh} /> Reorder Products
          </Link>
          </Box>
          <Box p={1}>
            <Link
              href={'/contact'}
              fontSize={'md'}
              fontWeight={600}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              <Icon w={3} h={3} as={MdContactPhone} /> Contact Us
            </Link>
          </Box>
      </Stack>
    );
  };
  
//   const DesktopSubNav = ({ label, href, subLabel }) => {
//     return (
//       <Link
//         href={href}
//         role={'group'}
//         display={'block'}
//         rounded={'md'}
//         _hover={{ bg: 'brand.900' }}>
//         <Stack direction={'row'} align={'center'}>
//           <Box>
//             <Text
//               transition={'all .3s ease'}
//               _groupHover={{ color: 'brand.100' }}
//               fontWeight={500}>
//               {label}
//             </Text>
//           </Box>
//           <Flex
//             transition={'all .3s ease'}
//             transform={'translateX(-10px)'}
//             opacity={0}
//             _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
//             justify={'flex-end'}
//             align={'center'}
//             flex={1}>
//             <Icon color={'brand.100'} w={5} h={5} as={ChevronRightIcon} />
//           </Flex>
//         </Stack>
//       </Link>
//     );
// };

const DesktopSubNav = ({ label, href, subLabel, children }) => {
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Popover trigger={'hover'} placement={'right-start'}>
      <PopoverTrigger>
        <Link
          href={href}
          role={'group'}
          display={'block'}
          rounded={'md'}>
          <Stack direction={'row'} align={'center'}>
            <Box>
              <Text
                transition={'all .3s ease'}
                _groupHover={{ color: 'brand.100' }}
                fontWeight={500}>
                {label}
              </Text>
            </Box>
            <Flex
              transition={'all .3s ease'}
              transform={'translateX(-10px)'}
              opacity={0}
              _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
              justify={'flex-end'}
              align={'center'}
              flex={1}>
              <Icon color={'brand.100'} w={5} h={5} as={ChevronRightIcon} />
            </Flex>
          </Stack>
        </Link>
      </PopoverTrigger>
      {children && (
        <PopoverContent
          border={0}
          boxShadow={'xl'}
          bg={popoverContentBgColor}
          color={linkHoverColor}
          p={4}
          rounded={'none'}
          minW={'sm'}>
          <Stack>
            {children.map((child) => (
              <DesktopSubNav key={child.label} {...child} />
            ))}
          </Stack>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default DesktopNav