import { Box, Heading, Text } from '@chakra-ui/react';

export default function NotFoundOrders() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        as="h1"
        size="4xl"
        color={'brand.400'}>
        Sorry
      </Heading>
      <Text fontSize="18px" mt={10} mb={2}>
        No previous orders found
      </Text>
      <Text color={'gray.500'} mb={6}>
        Once you place an order with us, this page will contain all previous orders.
      </Text>
    </Box>
  );
}