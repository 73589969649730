import { Box, Heading, Text, Button, Icon } from '@chakra-ui/react';
import { MdFavorite } from 'react-icons/md';

export default function NotFoundSaved() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        as="h1"
        size="4xl"
        color={'brand.400'}>
        Sorry
      </Heading>
      <Text fontSize="18px" mt={10} mb={2}>
        No saved products found
      </Text>
      <Text color={'gray.500'} mb={6}>
        As you browse our shop press the <Button leftIcon={<Icon boxSize={4} as={MdFavorite} />} size={'xs'} variant='solid' colorScheme='red'>SAVE</Button> on any item to add it to your saved products list.
      </Text>
    </Box>
  );
}