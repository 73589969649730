import {
    Box,
    Button,
    Container,
    Flex,
    Icon,
    Heading,
    HStack,
    Link,
    Progress,
    Stack,
    Text,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    Input
  } from '@chakra-ui/react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { FaArrowRight, FaStickyNote } from 'react-icons/fa'
import { MdAccountCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { CustomToast } from '../../Helpers/AddToast'
import { checkCart, createOrder, userDetails } from '../../Helpers/Queries'
import CartContext from '../../Hooks/CartContext'
import NotFoundCart from '../NotFound/NotFoundCart'
import NotFoundUser from '../NotFound/NotFoundUser'
import { CartOrderSummary } from './../Cart/CartOrderSummary'
const Checkout = () => {
    const {cart, setcart} = useContext(CartContext)
    const { addToast } = CustomToast();
    const [notes, setNotes] = useState();
    const [po, setPo] = useState();
    const navigate = useNavigate()
    const {
        isLoading: loadingCart,
        error: errorCart,
        data: cartResults,
      } = useQuery(['checkCart', {cart: cart}], checkCart)
    const {
        isLoading: loadingUser,
        error: errorUser,
        data: userResults,
      } = useQuery(['userDetails'], userDetails)

    const mutation = useMutation(createOrder, {
      onSuccess: (res) => {
          if(res.data.error){
              addToast({title: "Oh No!", message: res.data.message, type: "error"})
          } else {
              addToast({title: "Success!", message: res.data.message, type: "success"})
              setcart([])
              localStorage.removeItem("cart")
              navigate('/order-success/'+res.data.orderid)
          }
      },
      onError: (err) => {
          addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
      }
    })

    function createAddress(){
      let address1
      let address2
      let unit = userResults.data.address.street_unit ? userResults.data.address.street_unit+"/" : ""
      address1 = unit + userResults.data.address.street_number + " " + userResults.data.address.street_name
      address2 = userResults.data.address.suburb + ", " + userResults.data.address.state + ", " + userResults.data.address.postcode
      return <span>{address1}<br />{address2}</span>
    }

    function sendOrder(){
      let cartResults = cart
      let noteResults = notes
      let poResults = po
      let addressResults = {
        street_unit: userResults.data.address.street_unit,
        street_number: userResults.data.address.street_number,
        street_name: userResults.data.address.street_name,
        suburb: userResults.data.address.suburb,
        state: userResults.data.address.state,
        country: userResults.data.address.country,
        postcode: userResults.data.address.postcode,
        company_name: userResults.data.address.company_name,
      }
      let customerResults = {
        firstname: userResults.data.contact.firstname,
        lastname: userResults.data.contact.lastname,
        contact: userResults.data.contact.contact,
        email: userResults.data.contact.email
      }
      mutation.mutate({cartResults, noteResults, addressResults, customerResults, poResults})
    }

    if(loadingCart || loadingUser){
        return (
            <Container maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
                <Progress size='lg' colorScheme={'blue'} isIndeterminate />
            </Container>
        )
    }

    if(errorCart || !cartResults){
        return <NotFoundCart />
    }

    if(errorUser || !userResults){
      return <NotFoundUser />
  }

    return(
    <Box
      maxW={{ base: '3xl', lg: '7xl' }}
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align={{ lg: 'flex-start' }}
        spacing={{ base: '8', md: '16' }}
      >
        <Stack flex="2" spacing={5}>
          <Stack bgColor={'white'} padding="8" spacing={{ base: '6', md: '8' }}>
          <Heading fontSize="2xl" fontWeight="extrabold">
            Checkout
          </Heading>
          <Stack spacing={2}>
            <Text fontSize={'xl'}>This order will be delivered to</Text>
            <Text fontWeight={'bold'}>{userResults.data.address.company_name}</Text>
            <Text>{userResults.data.contact.firstname+' '+userResults.data.contact.lastname} - {userResults.data.contact.email} - {userResults.data.contact.contact}</Text>
            <Text>{createAddress()}</Text>
          </Stack>
          <Text pt={0} mt={0}>If you need to make changes to these details, please visit the <Button onClick={()=>navigate("/account")} leftIcon={<Icon as={MdAccountCircle} />} size='xs' colorScheme={'blue'} fontWeight={'bold'} textAlign={'center'}>My Account</Button> page!</Text>
          </Stack>
          <Stack bgColor={'white'} padding="8" spacing={{ base: '6', md: '8' }}>
          <Heading fontSize="2xl" fontWeight="extrabold">
            Additional Notes
          </Heading>
          <FormControl>
            <FormLabel htmlFor="notes">Please write any notes about this order below</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaStickyNote} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="notes"
                id="notes"
                placeholder='Enter your notes here....'
                value={notes}
                onChange={e=>setNotes(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          </Stack>
          <Stack bgColor={'white'} padding="8" spacing={{ base: '6', md: '8' }}>
          <Heading fontSize="2xl" fontWeight="extrabold">
            Purchase Order Number
          </Heading>
          <FormControl>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaStickyNote} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="po_number"
                id="po_number"
                placeholder='Enter your purchase order number here....'
                value={po}
                onChange={e=>setPo(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          </Stack>
        </Stack>
  
        <Flex bgColor={'white'} direction="column" align="center" flex="1">
          <CartOrderSummary order={cartResults.data.order[0]} submit={true} />
          <Stack padding="8" width="full">
          <Button onClick={()=>sendOrder()} colorScheme="blue" size="lg" fontSize="md" rightIcon={<FaArrowRight />}>
            Send Order
          </Button>
          </Stack>
          <HStack my="4" fontWeight="semibold">
            <p>or</p>
            <Link onClick={()=>navigate("/")} color={'brand.400'}>Continue shopping</Link>
          </HStack>
        </Flex>
      </Stack>
    </Box>
    )
  }

  export default Checkout