import { VStack } from "@chakra-ui/react"
import FeaturedProducts from "../Callouts/FeaturedProducts"

const HomeLayout = (props) => {

    return (
        <VStack
            minH={'calc(100% - 183px)'}
            spacing={2}
            py={5}
            >
            <FeaturedProducts />
        </VStack>
    )
}

export default HomeLayout