import {
  Box,
  Stack,
  Text,
  Image,
  Flex,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  HStack,
  Tooltip,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { FaBox, FaLayerGroup, FaPallet } from 'react-icons/fa';

const ProductInfo = (props) => {
  const { addToCart, product } = props
  return (
      <SimpleGrid
        columns={1}>
        <Flex>
          <Image
            mt={3}
            rounded={'md'}
            alt={'Featured Products'}
            fallbackSrc="https://manager.myorder.direct/assets/images/no_image.png"
            src={"https://manager.myorder.direct/uploads/"+product.thumbnail}
            fit={'cover'}
            align={'center'}
            w={'100%'}
            h={'500px'}
          />
        </Flex>
        <Stack mt={3} spacing={4}>
          <Box as={'header'}>
            <Heading
              textTransform={'uppercase'}
              lineHeight={1.1}
              fontWeight={600}
              fontSize={'3xl'}>
              {product.name}
            </Heading>
            {
                            (product.gst === "1")
                                ? 
                                <Text
                                color={'gray.900'}
                                fontWeight={300}
                                fontSize={'2xl'}>
                                ${parseFloat(product.sellinc/1.1).toFixed(2)}
                              </Text>
                                :
                                <Text
                                color={'gray.900'}
                                fontWeight={300}
                                fontSize={'2xl'}>
                                ${product.sellinc}
                              </Text>
                        }
          </Box>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.200', 'gray.600')}
              />
            }>
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('yellow.500', 'yellow.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                dangerouslySetInnerHTML={{__html: product.extendedinfo}}
                mb={'4'} />

              {/* <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    Between lugs:
                  </Text>{' '}
                  20 mm
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    Bracelet:
                  </Text>{' '}
                  leather strap
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    Case:
                  </Text>{' '}
                  Steel
                </ListItem>
              </List> */}
            </Box>
          </Stack>
          <Stack justify={'flex-start'} direction={'row'} spacing={6}>
              <HStack>
                  {(product.cartonqty !== "0") &&
                  <Tooltip hasArrow label='Carton Quantity' closeDelay={500}>
                      <Tag size={'sm'} variant='outline' colorScheme='green'>
                          <TagLeftIcon as={FaBox} />
                          <TagLabel>{product.cartonqty}</TagLabel>
                      </Tag>
                  </Tooltip>
                  }
                  {(product.layerqty !== "0") &&
                  <Tooltip hasArrow label='Layer Quantity' closeDelay={500}>
                      <Tag size={'sm'} variant='outline' colorScheme='green'>
                          <TagLeftIcon as={FaLayerGroup} />
                          <TagLabel>{product.layerqty}</TagLabel>
                      </Tag>
                  </Tooltip>
                  }
                  {(product.palletqty !== "0") &&
                  <Tooltip hasArrow label='Pallet Quantity' closeDelay={500}>
                      <Tag size={'sm'} variant='outline' colorScheme='green'>
                          <TagLeftIcon as={FaPallet} />
                          <TagLabel>{product.palletqty}</TagLabel>
                      </Tag>
                  </Tooltip>
                  }
              </HStack>
          </Stack>
          <hr />
          <Stack flex={1} justify={'center'} direction={'row'} spacing={6}>
                  {addToCart}
          </Stack>
        </Stack>
      </SimpleGrid>
  );
}

export default ProductInfo