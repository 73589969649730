import {
    Box,
    Flex,
    IconButton,
    Collapse,
    useDisclosure,
    Progress
  } from '@chakra-ui/react';
  import {
    HamburgerIcon,
    CloseIcon,
  } from '@chakra-ui/icons';
import MobileNav from './Menu/Mobile';
import DesktopNav from './Menu/Desktop';
import { useQuery } from '@tanstack/react-query';
import { getCategoriesList } from '../../Helpers/Queries';
import { CustomToast } from '../../Helpers/AddToast'
  
function Menu() {
  const { addToast } = CustomToast();
    let NAV_ITEMS = []
    const { isLoading, isError, data } = useQuery({
        queryKey: ['categoriesList'],
        queryFn: getCategoriesList,
      })
    const { isOpen, onToggle } = useDisclosure();

    if(!isLoading && !isError){
        NAV_ITEMS = data.data
    }

    if(isError){
      addToast({title: "Oh No!", message: "An error occured loading the categories list.", type: "error"})
    }

    return (
      <Box sx={{'@media print': {display: 'none',},}}>
        <Flex
          background={'rgb(0,51,153)'}
          bgGradient={'linear-gradient(90deg, rgba(0,51,153,1) 0%, rgba(0,153,255,1) 30%, rgba(0,51,153,1) 50%, rgba(0,153,255,1) 70%, rgba(0,51,153,1) 100%)'}
          color={'grey.800'}
          minH={'90px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={2}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
                color={'white'}
                _hover={{
                  bg: 'brand.400',
                }}
                onClick={onToggle}
                icon={
                    isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                }
                variant={'ghost'}
                aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>  
            <Flex display={{ base: 'none', md: 'flex' }}>
              {(isLoading) ? <Progress size='lg' colorScheme={'gray'} isIndeterminate /> : <DesktopNav NAV_ITEMS={NAV_ITEMS} />}
            </Flex>
          </Flex>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
        {(isLoading) ? <Progress size='lg' colorScheme={'gray'} isIndeterminate /> : <MobileNav NAV_ITEMS={NAV_ITEMS} /> }
        </Collapse>
      </Box>
    );
  }

export default Menu