import { useEffect } from "react";
import { Container, Flex, Progress, SimpleGrid, VStack } from "@chakra-ui/react";
import TileLayout from "../ProductTile/TileLayout";
import { useQuery } from "@tanstack/react-query";
import { getProductListBySubSubCategory } from "../../Helpers/Queries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "@choc-ui/paginator";
import NotFoundProducts from "../NotFound/NotFoundProducts";
import Banner from "../Callouts/Banner";
import { CustomToast } from "../../Helpers/AddToast";

function SubSubCategory() {
  const { addToast } = CustomToast();
  let products = [];
  const { subsubcategory, categoryname, subcategoryname, subsubcategoryname } = useParams();
  const [page, setPage] = useState(1);
  const { isLoading, isError, data } = useQuery({
    queryKey: ["productListBySubSubCategory", page],
    queryFn: () => getProductListBySubSubCategory(subsubcategory, page),
    keepPreviousData: true,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [page]);

  if (isError) {
    addToast({ title: "Oh No!", message: "An error occured loading the product list.", type: "error" });
  }

  if (isLoading) {
    return (
      <Container maxW="container.xl" bg="white" py={5} minH={"100%"} color="black">
        <Progress size="lg" colorScheme={"blue"} isIndeterminate />
      </Container>
    );
  }

  if (!isLoading && !isError) {
    if (data.data.products.length === 0) {
      return <NotFoundProducts />;
    }
    products = data.data.products.map((val) => {
      return <TileLayout key={val.UID} product={val} />;
    });
  }

  return (
    <>
      <VStack minH={"calc(100% - 183px)"} spacing={2} py={5}>
        <Container maxW="container.xl" bg="white" py={5} minH={"100%"} color="black">
          <Banner
            pageName={decodeURI(categoryname)}
            subText={decodeURI(subcategoryname)}
            subSubText={decodeURI(subsubcategoryname)}
            align="center"
            backgroundImage="https://images.unsplash.com/photo-1523447704114-37cea08ee064?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"
          />
        </Container>
        <Container maxW="container.xl" bg="white" h={"100%"} py={5} minH={"100%"} color="black">
          <SimpleGrid columns={[1, null, 2, 3, 4]} spacing="10px">
            {products}
          </SimpleGrid>
        </Container>
        <Flex w="full" p={50} alignItems="center" justifyContent="center">
          <Pagination
            defaultCurrent={1}
            colorScheme={"blue"}
            defaultPageSize={50}
            total={data.data.count}
            paginationProps={{
              display: "flex",
            }}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </VStack>
    </>
  );
}

export default SubSubCategory;
