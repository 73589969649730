import React, { useState, useEffect } from 'react';
import API from "./API"

const WholesalerLogo = (props) => {
    const [_wholesaler] = useState(props.wholesaler);
    const [_isLoading, setIsLoading] = useState(true);
    const [_storeLogo, setStoreLogo] = useState("/assets/images/no_image.png");
    useEffect(() => {
        API.get("/get_wholesalers_logo.php?uid="+_wholesaler, {headers: {"Content-Type": "application/json", "SECTOKEN": localStorage.getItem("sectoken")}}).then(res => {(!res.data.error) && setStoreLogo(res.data.data); setIsLoading(false)}).catch(res=>{setIsLoading(false)})
    }, [_wholesaler]);

    let storeLogo
    if(!_isLoading){
        storeLogo = <img alt="logo" src={_storeLogo} style={{maxWidth:"200px", maxHeight:"200px", width:"100%"}} />
    }
    return (
        <>{storeLogo}</>
    );
}

export default WholesalerLogo;