import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    Image,
    Icon
  } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendResetLink } from '../../Helpers/Queries';
import { CustomToast } from '../../Helpers/AddToast'
import { MdLink } from 'react-icons/md';


const ForgotPassword = (props) => {
    const { addToast } = CustomToast();
  const navigate = useNavigate()
  const [email, setEmail] = useState("")

  const mutation = useMutation(sendResetLink, {
    onSuccess: (res) => {
        if(res.data.error){
            addToast({title: "Oh No!", message: res.data.message, type: "error"})
        } else {
            addToast({title: "Success!", message: res.data.message, type: "success"})
            navigate('/login')
        }
    },
    onError: (err) => {
        addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
    }
  })

  return (
    <Box
    position="relative"
      border="20px solid"
      borderColor="#b2d9f3"
      borderRadius="60px"
      padding="0px"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: "-20px",
        borderRadius: "60px",
        border: "20px solid #b2d9f3",
      }}
      >
    <Stack spacing={8} maxW={'md'} pb={10} pt={8} px={4}>
        <Stack align={'center'}>
          <Image src="/logos/loop_logo.png" maxHeight={'200'} alt="LOOP" />
          <Box height={'6px'} w={'100%'} backgroundColor={'#E8E8E8'} />
        </Stack>
        <Stack align={'left'}>
          <Heading fontSize={'2xl'} color={'theme.blueText'}>Forgot Password</Heading>
          <Text fontSize={'md'} color={'lightgrey'}>Enter your email address below and we'll send you a link to reset your password.</Text>
        </Stack>
        <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input isDisabled={mutation.isLoading} value={email} onChange={(e)=>setEmail(e.target.value)} type="email" />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={'column'}
                align={'end'}>
                <Link color={'brand.400'} href={"/login"} zIndex={1}>Go back to login</Link>
              </Stack>
              <Button onClick={()=>mutation.mutate({email})} colorScheme={'brand.400'} isDisabled={(!email)} isLoading={mutation.isLoading} leftIcon={<Icon as={MdLink} />} >
                Send Reset Link
              </Button>
            </Stack>
          </Stack>
      </Stack>
      </Box>
    );
  }

export default ForgotPassword