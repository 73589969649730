import { Container, Tab, TabList, TabPanel, TabPanels, Tabs, Icon } from "@chakra-ui/react"
import { FaPaperclip, FaUser } from "react-icons/fa"
import AccountDetails from "./AccountDetails"
import OrderHistory from "./OrderHistory"

const MyAccount = props => {
    return <Container maxWidth={'7xl'} pb={0} mb={0}>
        <Tabs isFitted isLazy>
        <TabList>
        <Tab fontWeight={'bold'} color={'brand.400'}><Icon as={FaPaperclip} mr={2} />Order History</Tab>
        <Tab fontWeight={'bold'} color={'brand.400'}><Icon as={FaUser} mr={2} />Account Details</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
                <OrderHistory />
            </TabPanel>
            <TabPanel>
                <AccountDetails />
            </TabPanel>
        </TabPanels>
    </Tabs>
  </Container>
}

export default MyAccount