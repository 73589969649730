import { Box, Flex, Heading, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"



const Banner = (props) => {
    const size = useBreakpointValue({ base: 4, md: 8 })
    const fonts = useBreakpointValue({ base: '3xl', md: '4xl' })
    const subfonts = useBreakpointValue({ base: 'xl', md: '2xl' })
    const { pageName, backgroundImage, subText, align, subSubText } = props

    function subTextBuilder(text, subText){
      if(text){
        return <Text
        color={'white'}
        fontWeight={500}
        lineHeight={1}
        fontSize={subfonts}>
        {(subText) ? text+" ("+subText+")" : text}
      </Text>
      }
      return
    }

    if(backgroundImage){
        return (
            <Flex
              w={'full'}
              h={'250px'}
              backgroundImage={
                'url('+backgroundImage+')'
              }
              backgroundSize={'cover'}
              backgroundPosition={'center center'}>
              <VStack
                w={'full'}
                justify={'center'}
                px={size}
                bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                <Stack maxW={'2xl'} align={(align) ? align : 'flex-start'} spacing={6}>
                  <Text
                    color={'white'}
                    fontWeight={700}
                    lineHeight={1.2}
                    fontSize={fonts}>
                    {pageName}
                  </Text>
                  {subTextBuilder(subText, subSubText)}
                </Stack>
              </VStack>
            </Flex>
          );
    }

    return (<Box>
     <Heading size='md'>
        {pageName}
    </Heading> 
    </Box>)
}

export default Banner