import { createContext, useContext, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import TopHeader from "../Components/Header/TopHeader";
import Menu from "../Components/Header/Menu";
import { Box, Container } from "@chakra-ui/react";
import FloatingButton from "../Components/Cart/FloatingButton";
import Search from "../Components/Header/Search";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [sectoken, setsectoken] = useState(null);

  return (
    <AuthContext.Provider value={{ sectoken, setsectoken}}>
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = () => {
  const { sectoken } = useAuth();
  const location = useLocation();
  if (!sectoken) {
    return (
      <Navigate
        to={{ pathname: "/login", state: { from: location } }}
        replace
      />
    );
  }

  return (
    <>
        <TopHeader isAuth={true}  />
        <Menu />
        <Container maxW='container.xl' bg='white' py={5} mt={5} color='black' sx={{'@media print': {display: 'none',},}}>
                <Search />
        </Container>
        <Box
            minH={{base: 'calc(100% - 328px)', sm: 'calc(100% - 352px)', md: 'calc(100% - 352px)', lg: 'calc(100% - 328px)'}}>
            <Outlet />
        </Box>
        <FloatingButton />
    </>
    
  );
};