import { Container, Progress, SimpleGrid, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { getOrder } from "../../Helpers/Queries"
import NotFoundOrders from "../NotFound/NotFoundOrders"

function formatDate(date){
    date = date.replace(/ /g,"T")
    var d = new Date(date);
    d = d.toLocaleTimeString("en-AU", {day: '2-digit', month:'2-digit', year: '2-digit'})
    return d
}

const ViewOrder = props => {
    let params = useParams()
    const { orderUID } = params
    const { isLoading, isError, data } = useQuery({
        queryKey: ['getOrder', orderUID],
        queryFn: ()=>getOrder(orderUID)
    })

    if(isLoading){
        return (
            <Container backgroundColor={'white'} maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
                <Progress size='lg' colorScheme={'blue'} isIndeterminate />
            </Container>
            )
        }
    if(isError || !data || data.data.error || data.data.orderDetails){
        return <NotFoundOrders />
    }

    let orderHeader = data.data.orderheader[0]
    let orderDetails = data.data.orderdetails
    let orderAddress = data.data.orderaddress[0]
        
    let orderid = "#INV"+orderHeader.id.padStart(8,"0")
    let notes = (orderHeader.notes) ? <Text><span><strong>Order Notes:&nbsp;</strong></span>{orderHeader.notes}</Text> : ""

    let customerunit = (orderAddress.street_unit) ? orderAddress.street_unit+"/" : ""
    let customeraddress1 = customerunit + orderAddress.street_number + " " + orderAddress.street_name
    let customeraddress2 = orderAddress.suburb + ", " + orderAddress.state + ", " + orderAddress.postcode
    let ordercustomerunit = (orderAddress.street_unit) ? orderAddress.street_unit+"/" : ""
    let ordercustomeraddress1 = ordercustomerunit + orderAddress.street_number + " " + orderAddress.street_name
    let ordercustomeraddress2 = orderAddress.suburb + ", " + orderAddress.state + ", " + orderAddress.postcode
    let gsttotal = 0

    let products = <TableContainer><Table size={'sm'} width={'100%'} variant={'simple'}>
                        <Thead>
                        <Tr>
                            <Th textAlign="left">Order Code</Th>
                            <Th textAlign="left">Product</Th>
                            <Th textAlign="center">Quantity</Th>
                            <Th textAlign="right">Price</Th>
                            <Th textAlign="right">Total</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                            {orderDetails.map((val, idx)=>{
                                let ordernumbers
                                let sell = val.sellinc
                                let gst = 0
                                if(val.gst === "1"){
                                    gst = parseFloat((parseFloat(sell) / 11)).toFixed(2)
                                    sell = parseFloat((parseFloat(sell) / 1.1)).toFixed(2)
                                }
                                let total
                                if(val.weighted === "0"){
                                    ordernumbers = (val.orderqty !== val.pickedqty) ? <span><span className="text-black-20">{val.pickedqty} |</span> {val.orderqty}</span> : val.orderqty
                                    total = (sell*val.orderqty).toFixed(2)
                                    gsttotal = parseFloat(parseFloat(gsttotal) + parseFloat(gst*val.orderqty)).toFixed(2)
                                } else {
                                    if((parseFloat(val.actualweight).toFixed(2) !== parseFloat(parseFloat(val.orderqty)*parseFloat(val.cartonqty)).toFixed(2)) && val.actualweight !== null){
                                        ordernumbers = <span><span className="text-black-20">{parseFloat(parseFloat(val.orderqty)*parseFloat(val.cartonqty)).toFixed(2)}kg ({val.orderqty}) |</span> {parseFloat(val.actualweight).toFixed(2)}kg</span>
                                    } else {
                                        ordernumbers = <span>{parseFloat(parseFloat(val.orderqty)*parseFloat(val.cartonqty)).toFixed(2)}kg ({val.orderqty})</span>
                                    }                                   
                                    sell = (sell).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })+"kg";
                                    total = (sell*val.actualweight).toFixed(2)
                                    gsttotal = parseFloat(parseFloat(gsttotal) + parseFloat(gst*val.actualweight).toFixed(2)).toFixed(2)
                                }
                                
                                return  <Tr key={idx}>
                                            <Td>
                                                {val.ordercode}
                                            </Td>
                                            <Td>
                                                <Text textTransform={'uppercase'}>{(val.gst === "1") && <span>*</span>}{val.name}</Text>
                                            </Td>
                                            <Td textAlign="center">
                                                {ordernumbers}
                                            </Td>
                                            <Td textAlign="right">
                                                ${sell}
                                            </Td>
                                            <Td textAlign="right">
                                                ${parseFloat(total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </Td>
                                        </Tr>
                            })}
                            <Tr>
                                <Td colSpan="4" textAlign="right"><strong>Subtotal</strong></Td>
                                <Td colSpan="4" textAlign="right">${parseFloat(parseFloat(orderHeader.ordertotal)-parseFloat(orderHeader.freight)-parseFloat(orderHeader.surcharge)-parseFloat(gsttotal)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan="4" textAlign="right"><strong>GST</strong></Td>
                                <Td colSpan="4" textAlign="right">${parseFloat(parseFloat(gsttotal)-parseFloat(orderHeader.freight)-parseFloat(orderHeader.surcharge)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan="4" textAlign="right"><strong>Freight Charge</strong></Td>
                                <Td colSpan="4" textAlign="right">${parseFloat(orderHeader.freight).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan="4" textAlign="right"><strong>Payment Surcharge</strong></Td>
                                <Td colSpan="4" textAlign="right">${parseFloat(orderHeader.surcharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Td>
                            </Tr>
                            <Tr>
                                <Td colSpan="4" textAlign="right"><strong>Order Total</strong></Td>
                                <Td colSpan="4" textAlign="right"><strong>${parseFloat(parseFloat(orderHeader.ordertotal)+parseFloat(orderHeader.freight)+parseFloat(orderHeader.surcharge)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong></Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    </TableContainer>
               
    return <Container maxWidth={'7xl'} backgroundColor={'white'} py={6} my={6}>
        <Text fontSize={'2xl'} fontWeight={'bold'}>{orderid}</Text>
        <Text fontSize={'sm'}>{formatDate(orderHeader.order_created)}</Text>
    <Stack direction={['column', 'row']} spacing={1}>
        <SimpleGrid
        columns={{
            base: 1,
            md: 2,
        }}
        w={{
            base: '100%',
            md: "full",
        }}
        textTransform="uppercase"
        py={{
            base: 1,
            md: 4,
        }}
        fontSize="md"
        >
            <Text><span className="order-total"><strong>Billing Address</strong></span><br />{orderAddress.company_name}<br />{orderAddress.customer}<br />{customeraddress1}<br />{customeraddress2}</Text>
            <Text align={{base:'left',md:'right'}}><span className="order-total"><strong>Shipping Address</strong></span><br />{orderAddress.company_name}<br />{orderAddress.customer}<br />{ordercustomeraddress1}<br />{ordercustomeraddress2}</Text>
        </SimpleGrid>
    </Stack>
    {notes}
    <Stack mt={10} width={'100%'}>
    {products}
    <small>* Indicates taxable item.</small>
    </Stack>
    </Container>
}

export default ViewOrder