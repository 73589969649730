import {
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import Login from './Components/Auth/Login';
import Layout from './Helpers/Layout';
import HomeLayout from './Components/HomeLayout/HomeLayout';
import NotFound from "./Components/NotFound/NotFound";
import { Box, Progress } from "@chakra-ui/react";
import Footer from "./Components/Footer/Footer";
import { RequireAuth, useAuth } from "./Hooks/AuthContext";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { validateToken } from "./Helpers/Queries";
import Category from "./Components/Shop/Category";
import SubCategory from "./Components/Shop/SubCategory";
import Saved from "./Components/Shop/Saved";
import Reorder from "./Components/Shop/Reorder";
import Contact from "./Components/Contact/Contact";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import CartContext from "./Hooks/CartContext";
import PreviewCart from "./Components/Cart/PreviewCart";
import Search from "./Components/Shop/Search";
import Checkout from "./Components/Checkout/Checkout";
import MyAccount from "./Components/Account/MyAccount";
import ViewOrder from "./Components/Account/ViewOrder";
import ViewInvoice from "./Components/Account/ViewInvoice";
import CreateAccount from "./Components/Auth/CreateAccount";
import OrderSuccess from "./Components/Checkout/OrderSuccess";
import SubSubCategory from "./Components/Shop/SubSubCategory";



function App(){
  const [isLoaded, setIsLoaded] = useState(false)
  const [cart, setcart] = useState((localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
  const CartValue = useMemo(()=> ({cart, setcart}), [cart, setcart]);
  const navigate = useNavigate()
  const { setsectoken } = useAuth()
  useEffect(() => {
    setIsLoaded(false)
    checkAuth(localStorage.getItem("sectoken"))
    // eslint-disable-next-line
  }, []);

  function checkAuth(token){
    if(token){
      validate.mutate({token})
    } else {
      setIsLoaded(true)
    }
  }

  const validate = useMutation(validateToken, {
    onSuccess: (res) => {
      let tok
      if(!res.data.refresh){
        tok = res.data.sectoken.token
      } else {
        tok = res.data.sectoken
      }
      localStorage.setItem("sectoken", tok)
      setsectoken(tok)
      setIsLoaded(true)
    },
    onError: (err) => {
      localStorage.removeItem("sectoken")
      navigate("/login");
      setIsLoaded(true)
    }
  })

  if(!isLoaded){
    return <Box><Progress size='lg' colorScheme={'blue'} isIndeterminate /></Box>
  }
    
  return(
    <CartContext.Provider value={CartValue}>
      <Box>
        <Routes>
              <Route element={<Layout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/" element={<RequireAuth />}>
                <Route path="" element={<HomeLayout />} />
                <Route path="/account" element={<MyAccount />} />
                <Route path="/vieworder/:orderUID" element={<ViewOrder />} />
                <Route path="/viewinvoice/:orderUID" element={<ViewInvoice />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/reorder" element={<Reorder />} />
                <Route path="/saved" element={<Saved />} />
                <Route path="/search/:searchterm?" element={<Search />} />
                <Route path="/cart" element={<PreviewCart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/order-success/:orderid" element={<OrderSuccess />} />
                <Route path="/category/:category/:categoryname?" element={<Category />} />
                <Route path="/subcategory/:subcategory/:categoryname?/:subcategoryname?" element={<SubCategory />} />
                <Route path="/subsubcategory/:subsubcategory/:categoryname?/:subcategoryname?/:subsubcategoryname?" element={<SubSubCategory />} />
              </Route>
          </Routes>
          <Footer />
        </Box>
      </CartContext.Provider>
    )
}

export default App