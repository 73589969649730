import { Container, Flex, Progress, Stack } from "@chakra-ui/react"
import Pagination from "@choc-ui/paginator"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import NotFoundOrders from "../NotFound/NotFoundOrders"
import API from "./../../Helpers/API"
import OrderRow from "./OrderRow"

const OrderHistory = props => {
    const [page, setPage] = useState(1)
    const fetchOrders = (page = 1) => API.post("/post_customer_orders.php", {currentpage: page, count: 10, statusfilter: 0}, {headers: {"SECTOKEN" : localStorage.getItem("sectoken")}})

    const {
        isLoading,
        isError,
        data
      } = useQuery({
        queryKey: ['orders', page],
        queryFn: () => fetchOrders(page),
        keepPreviousData : true
      })

    if(isLoading){
    return (
        <Container backgroundColor={'white'} maxW='container.xl' bg='white' py={5} minH={'100%'} color='black'>
            <Progress size='lg' colorScheme={'blue'} isIndeterminate />
        </Container>
        )
    }
    if(isError || !data || data.data.error){
        return <NotFoundOrders />
    }

    if(data.data.data.length === 0){
      return <NotFoundOrders />
    }

    let orders = data.data.data.map((val, idx) => {
        return <OrderRow key={idx} order={val} />
    })

    return(<>
    <Flex
      w="full"
      backgroundColor={'white'}
      p={5}
      pb={0}
      mb={0}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        direction={{
          base: "column",
        }}
        mb={0}
        w="full"
      >
        {orders}
      </Stack>
      </Flex>
        <Flex
            w="full"
            p={10}
            mb={0}
            pb={0}
            alignItems="center"
            justifyContent="center"
        >
        <Pagination
            defaultCurrent={1}
            colorScheme={'blue'}
            defaultPageSize={10}
            total={data.data.count}
            paginationProps={{
            display: "flex",
            }}
            current={page}
            onChange={(page) => setPage(page)}
        />
      </Flex>
    </>)
}

export default OrderHistory