import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../Helpers/Queries';
import { useAuth } from '../../Hooks/AuthContext';
import { CustomToast } from '../../Helpers/AddToast'
import { useBreakpointValue } from "@chakra-ui/react"
import LoginLGdown from './LoginLGdown';
import LoginLGup from './LoginLGup';

function LGDown(props) {
  return <LoginLGdown {...props} />;
}
  
function LGUp(props) {
  return <LoginLGup {...props} />;
}

const Login = () => {
  const { addToast } = CustomToast();
  const { setsectoken } = useAuth()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const mutation = useMutation(login, {
    onSuccess: (res) => {
        localStorage.setItem("sectoken", res.data.sectoken)
        setsectoken(res.data.sectoken)
        navigate("/");
    },
    onError: (err) => {
      addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
    }
  })

  const Component = useBreakpointValue({ base: LGDown, lg: LGUp })

  const commonProps = {
    email,
    setEmail,
    password,
    setPassword,
    mutation,
  }

  return <Component {...commonProps} />;
}

export default Login;
