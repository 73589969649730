import axios from "axios";


export default axios.create({
  baseURL: "https://api.yourloop.store",
  headers: {
    "Content-type": "application/json",
    "CUID" : "1b9dbe15-300c-11ee-aa7f-00163cd1cd8e",
    "STORE_NAME" : "TPM Packaging Solutions",
    "STORE_CONTACT" : "(03) 9543 3100",
    "STORE_URL" : "https://tpm.yourloop.store/",
    "SHOP_TYPE" : "retail"
  }
});