import {
    Box,
    Container,
    Stack,
    Text,
} from '@chakra-ui/react';
   
  
const Footer = ({showReturn = false}) => {
    // if the url is /login, /register, or /forgot-password, showReturn will be true
    const url = window.location.pathname
    if(url === "/login" || url === "/register" || url === "/forgot-password"){
      showReturn = true
    }
    return (
      <Box
      sx={{'@media print': {display: 'none',},}}
      background={'rgb(0,51,153)'}
      bgGradient={'linear-gradient(90deg, rgba(0,51,153,1) 0%, rgba(0,153,255,1) 30%, rgba(0,51,153,1) 50%, rgba(0,153,255,1) 70%, rgba(0,51,153,1) 100%)'}
        color={'white'}
        minH={'53px'}
        position={''}
        bottom={0}>
        <Container
          as={Stack}
          maxW={'8xl'}
          py={3.5}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: !showReturn ? 'right' : 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          {showReturn && <Text fontWeight={'600'}>{'<'} RETURN TO WEBSITE</Text>}
          <Text fontWeight={'600'}>©TPM PACKAGING 2023 ALL RIGHTS RESERVED</Text>
        </Container>
      </Box>
    );
}

export default Footer