import {
    Container,
    Box,
    Text,
    Stack,
    InputGroup,
    Input,
    InputLeftElement,
    Icon,
    FormLabel,
    Button,
    Divider,
    FormControl,
    Select,
    Heading,
    Progress
  } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
  import { FaRegEnvelope, FaLock, FaRegUser, FaPhone, FaBuilding, FaLocationArrow, FaReceipt } from "react-icons/fa";
import { MdAccountCircle, MdAppSettingsAlt, MdEditRoad, MdHouse, MdOutlineHouseSiding, MdOutlineSave, MdQrCode, MdSingleBed } from "react-icons/md";
import { setAddress, setPassword, setPersonal, userDetails } from "../../Helpers/Queries";
import NotFoundUser from "../NotFound/NotFoundUser";
import { CustomToast } from '../../Helpers/AddToast'

const AccountDetails = props => {
    const queryClient = useQueryClient()
    const { addToast } = CustomToast();
    const ref = useRef(null);
    const ref1 = useRef(null);
    const [userPersonalData, setUserPersonalData] = useState({})
    const [userAddressData, setUserAddressData] = useState({})
    const [userPasswordData, setUserPasswordData] = useState({})
    const { isLoading, error, data } = useQuery(['lookupUserDetails'], userDetails)
    const personalMutation = useMutation(setPersonal, {
        onSuccess: (res) => {
            if(res.data.error){
                addToast({title: "Oh No!", message: res.data.message, type: "error"})
            } else {
                addToast({title: "Success!", message: "Personal Details have been saved successfully.", type: "success"})
                queryClient.invalidateQueries({ queryKey: ['lookupUserDetails'] })
                setUserPersonalData({})
            }
        },
        onError: (err) => {
          addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
        }
    })

    const addressMutation = useMutation(setAddress, {
        onSuccess: (res) => {
            if(res.data.error){
                addToast({title: "Oh No!", message: res.data.message, type: "error"})
            } else {
                addToast({title: "Success!", message: "Address Details have been saved successfully.", type: "success"})
                queryClient.invalidateQueries({ queryKey: ['lookupUserDetails'] })
                setUserAddressData({})
            }
        },
        onError: (err) => {
          addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
        }
    })

    const passwordMutation = useMutation(setPassword, {
        onSuccess: (res) => {
            if(res.data.error){
                addToast({title: "Oh No!", message: res.data.message, type: "error"})
            } else {
                addToast({title: "Success!", message: "Your password has been changed successfully.", type: "success"})
                setUserPasswordData({})
                ref.current.value = "";
                ref1.current.value = "";
            }
        },
        onError: (err) => {
          addToast({title: "Oh No!", message: (err.response.data.message) ? err.response.data.message : "An error occured, please try again.", type: "error"})
        }
    })

    const updatePersonalData = e => {
        setUserPersonalData({
            ...userPersonalData,
            [e.target.name]: e.target.value
        })
    }

    const updateAddressData = e => {
        setUserAddressData({
            ...userAddressData,
            [e.target.name]: e.target.value
        })
    }

    const updateAddressDataState = e => {
        setUserAddressData({
            ...userAddressData,
            state: e.target.value
        })
    }

    const updatePasswordData = e => {
        setUserPasswordData({
            ...userPasswordData,
            [e.target.name]: e.target.value
        })
    }

    function updateDetails(){
        if(Object.keys(userPersonalData).length === 0){
            addToast({title: "Success!", message: "Personal Details have been saved successfully.", type: "success"})
            return false
        }
        personalMutation.mutate(userPersonalData)
    }

    function updateAddressDetails(){
        if(Object.keys(userAddressData).length === 0){
            addToast({title: "Success!", message: "Address Details have been saved successfully.", type: "success"})
            return false
        }
        addressMutation.mutate(userAddressData)
    }

    function updatePassword(){
        if(Object.keys(userPasswordData).length !== 2){
            addToast({title: "Ooops!", message: "Please fill in both password fields", type: "info"})
            return false
        }
        passwordMutation.mutate(userPasswordData)
    }

    if(isLoading){
        return (
            <Container maxW='container.md' bg='white' py={5} minH={'100%'} color='black'>
                <Progress size='lg' colorScheme={'blue'} isIndeterminate />
            </Container>
        )
    }

    if(error){
        return <NotFoundUser />
    }
    let userAddress = data.data.address
    let userContact = data.data.contact
    return <Container maxW="container.md" backgroundColor={'white'} bg='white' py={5} minH={'100%'} color='black'>
    <Heading as="h4" size="md">
        <Icon as={MdAccountCircle} color="secondary.inputHelper" /> Personal Details
    </Heading>
    <Text color="gray.500" fontSize="md">
        Update your name, email, etc
    </Text>
    <form>
      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl>
            <FormLabel htmlFor="firstname">First Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaRegUser} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="firstname"
                id="firstname"
                defaultValue={userContact.firstname}
                onChange={updatePersonalData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="lastname">Last Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaRegUser} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="lastname"
                id="lastname"
                defaultValue={userContact.lastname}
                onChange={updatePersonalData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email Address <small>(This will update your login to the website)</small></FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon
                    as={FaRegEnvelope}
                    color="secondary.inputHelper"
                  />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="email"
                name="email"
                id="email"
                autoComplete="off"
                defaultValue={userContact.email}
                onChange={updatePersonalData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="contact">Contact Number <small>(e.g 0422123123 or 0354123123)</small></FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon
                    as={FaPhone}
                    color="secondary.inputHelper"
                  />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="number"
                maxLength="10"
                minLength="10"
                min="0100000000"
                max="0900000000"
                name="contact"
                id="contact"
                defaultValue={userContact.contact}
                onChange={updatePersonalData}
              />
            </InputGroup>
          </FormControl>
        </Stack>
        <Stack
        direction={["column", "row"]}
        spacing="1rem"
        justify="end"
        marginTop="2rem"
      >
        <Button isLoading={personalMutation.isLoading} loadingText={"Updating"} onClick={()=>updateDetails()} leftIcon={<Icon fontSize={'lg'} as={MdOutlineSave} />} type="button" colorScheme="blue">
          Update Details
        </Button>
      </Stack>
      </Box>
      <Divider
      marginTop="0"
      marginBottom="2rem"
      orientation="horizontal"
    />
    <Heading as="h4" size="md">
        <Icon as={MdHouse} color="secondary.inputHelper" /> Address Details
    </Heading>
    <Text color="gray.500" fontSize="md">
        Update your company, address, etc
    </Text>
      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl>
            <FormLabel htmlFor="companyname">Company Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaBuilding} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="companyname"
                id="companyname"
                defaultValue={userAddress.company_name}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="abn">ABN</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaReceipt} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="number"
                name="abn"
                id="abn"
                maxLength="11"
                minLength="11"
                max="99999999999"
                defaultValue={userAddress.abn}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="street_unit">Unit Number</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={MdOutlineHouseSiding} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="street_unit"
                id="street_unit"
                defaultValue={userAddress.street_unit}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="street_number">Street Number</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={MdSingleBed} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="street_number"
                id="street_number"
                defaultValue={userAddress.street_number}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="street_name">Street Name</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={MdEditRoad} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="street_name"
                id="street_name"
                defaultValue={userAddress.street_name}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="suburb">Suburb</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaLocationArrow} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="text"
                name="suburb"
                id="suburb"
                defaultValue={userAddress.suburb}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="postcode">Postcode</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={MdQrCode} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                type="number"
                min="1000"
                max="9999"
                maxLength="4"
                name="postcode"
                id="postcode"
                defaultValue={userAddress.postcode}
                onChange={updateAddressData}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="state">State</FormLabel>
            <Select onChange={updateAddressDataState} defaultValue={userAddress.state}>
                <option value="VIC">Victoria</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT ">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA ">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="WA ">Western Australia</option>
            </Select>
          </FormControl>
        </Stack>
        <Stack
        direction={["column", "row"]}
        spacing="1rem"
        justify="end"
        marginTop="2rem"
      >
        <Button onClick={()=>updateAddressDetails()} leftIcon={<Icon fontSize={'lg'} as={MdOutlineSave} />} type="button" colorScheme="blue">
          Update Address
        </Button>
      </Stack>
      </Box>
      <Divider
      marginTop="0"
      marginBottom="2rem"
      orientation="horizontal"
    />
      <Stack spacing={0} marginTop={2}>
        <Heading as="h4" size="md">
            <Icon as={MdAppSettingsAlt} color="secondary.inputHelper" /> Security settings
        </Heading>
        <Text color="gray.500" fontSize="md">
          <small>Password should be at least 6 characters in length and should include at least one upper case letter, and one number.</small>
        </Text>
      </Stack>

      <Box bg="secondary.card" rounded="lg" p={5}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="new_password">New password</FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaLock} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                name="new_password"
                id="new_password"
                type="password"
                placeholder="Enter your new password"
                autoComplete="new-password"
                onChange={updatePasswordData}
                ref={ref}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="new_password2">
                Confirm new password
              </FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Icon as={FaLock} color="secondary.inputHelper" />
                }
              />
              <Input
                focusBorderColor="main.500"
                name="new_password2"
                id="new_password2"
                type="password"
                placeholder="Confirm your new password"
                autoComplete="new-password"
                onChange={updatePasswordData}
                ref={ref1}
              />
            </InputGroup>
          </FormControl>
        </Stack>
        <Stack
        direction={["column", "row"]}
        spacing="1rem"
        justify="end"
        marginTop="2rem"
      >
        <Button onClick={()=>updatePassword()} leftIcon={<Icon fontSize={'lg'} as={MdOutlineSave} />} type="button" colorScheme="blue">
          Update Password
        </Button>
      </Stack>
      </Box>
    </form>
  </Container>
}

export default AccountDetails