import {
    Box,
    Stack,
    Icon,
    Flex,
    Button,
    Image
} from '@chakra-ui/react';
import { MdAccountCircle, MdFavorite, MdLogout } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom';
  
const TopHeader = (props) => {
    const { isAuth } = props
    const navigate = useNavigate()
    function logout(){
        localStorage.clear()
        navigate("/login")
    }
    return (
        <Box
        background={'rgb(0,51,153)'}
        bgGradient={'linear-gradient(90deg, rgba(0,51,153,1) 0%, rgba(0,153,255,1) 30%, rgba(0,51,153,1) 50%, rgba(0,153,255,1) 70%, rgba(0,51,153,1) 100%)'}
        color={'white'}
        align={'center'}
        sx={{'@media print': {display: 'none',},}}
        >
      <Flex
        maxW={'6xl'}
        minH={'53px'}
        align={'center'}
        justifyContent={'flex-end'}
        >
        {isAuth &&
        <>
         <Flex as={Link} to="/" flex={{ base: 1 }} justify={'start'}>
            <Image
                mt={1}
                maxW={'110px'}
                src='/logos/tpm_logo.png'
                backgroundColor={'white'}
                alt="TPM"
                rounded={'full'}
            />
        </Flex>
        <Stack
          justify={'flex-end'}
          direction={'row'}
          alignContent={'center'}
          alignItems={'center'}
          px={1}
          py={.5}
          rounded={5}
          spacing={{base: 1, lg: 6}}>
            
            <Button onClick={()=>navigate("/account")} pt={'2px'} border={'2px white solid'} leftIcon={<Icon mt={'-2px'} as={MdAccountCircle} />} size={{base: 'xs', lg: 'sm'}} colorScheme={'blue'} fontWeight={'normal'} textAlign={'center'}>My Account</Button>
            <Button onClick={()=>logout()} pt={'2px'} border={'2px white solid'} leftIcon={<Icon mt={'-2px'} as={MdLogout} />} size={{base: 'xs', lg: 'sm'}} colorScheme={'teal'} fontWeight={'normal'} textAlign={'center'}>Logout</Button>
            <Button size={{base: 'sm', lg: 'lg'}} border={'2px white solid'} fontWeight={'normal'} onClick={()=>navigate("/saved")} colorScheme={'red'} leftIcon={<Icon
                    as={MdFavorite}
                    w={6}
                    h={6}
                    color={'white'}
                />}>Saved Products</Button>
                
        </Stack>
        </>
        }
      </Flex>
    </Box>
    );
}

export default TopHeader